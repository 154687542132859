import {FormControlLabel, MenuItem, Select, Stack, TextField, Typography} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

function QueryForm({selection, setSelection, id, title}) {
    const orats = {
        "Call Volume": "call_volume",
        CVSD: "call_volume_all_time_num_of_std_dev",
        "Put Volume": "put_volume",
        PVSD: "put_volume_all_time_num_of_std_dev",
        "Put Call Ratio": "put_call_ratio",
        PCRSD: "put_call_ratio_all_time_num_of_std_dev",
        "Call Volume Dollar": "call_volume_dollar",
        CVDSD: "call_volume_dollar_all_time_num_of_std_dev",
        "Put Volume Dollar": "put_volume_dollar",
        PVDSD: "put_volume_dollar_all_time_num_of_std_dev",
        "Put Call Dollar Ratio": "put_call_dollar_ratio",
        PCDRSD: "put_call_dollar_ratio_all_time_num_of_std_dev",
        "Call Open Interest": "call_open_interest",
        COISD: "call_open_interest_all_time_num_of_std_dev",
        "Put Open Interest": "put_open_interest",
        POISD: "put_open_interest_all_time_num_of_std_dev"
    }

    function updateSelection(e, k) {
        if (selection[id][orats[k]] === undefined)
            selection[id][orats[k]] = {"value": 0, "logic": ">="};
        selection[id][orats[k]].selected = e.target.checked;
        setSelection({...selection});
    }

    function logicChange(e, k) {
        if (selection[id][orats[k]] === undefined)
            selection[id][orats[k]] = {"selected": false}
        selection[id][orats[k]].logic = e.target.value;
        setSelection({...selection})
    }


    function valueChange(e, k) {
        if (selection[id][orats[k]] === undefined)
            selection[id][orats[k]] = {"selected": false}
        selection[id][orats[k]].value = e.target.value;
        setSelection({...selection})
    }

    return (
        <div className="App">
            <Typography padding={3}>{title}</Typography>
            <Stack spacing={1} width={500}>
                {
                    Object.keys(orats).map(k => {
                        return (
                            <Stack key={k} direction="row" spacing={3}>
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={selection[id][orats[k]]?.selected ?? false}
                                        onChange={(e) => updateSelection(e, k)}/>}
                                    label={k}
                                />
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selection[id][orats[k]]?.logic ?? '>='}
                                    label="Age"
                                    size="small"
                                    onChange={(e) => logicChange(e, k)}
                                >
                                    <MenuItem value={">="}>≥</MenuItem>
                                    <MenuItem value={"<="}>≤</MenuItem>
                                </Select>
                                <TextField
                                    id="standard-number"
                                    label="Value"
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={selection[id][orats[k]]?.value ?? 0}
                                    onChange={(e) => valueChange(e, k)}
                                    variant="standard"
                                />
                            </Stack>
                        )
                    })
                }
            </Stack>
        </div>
    );
}

export default QueryForm;