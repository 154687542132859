import './App.css';
import {Box, Button, MenuItem, Select, Slider, Stack, Switch, TextField, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import QueryForm from "./QueryForm";

let url = "https://api.fusionpointcapital.com";

if (process.env.NODE_ENV === "development") {
    // please do not change and commit this
    url = "http://localhost:8080";
}

// url = "http://localhost:8080";

function App() {

    const [selection, setSelection] = useState({
        1: {},
        2: {},
        3: {},
        4: {},
        "days_range": 7,
        "operator": "OR",
        "category": "all",
        "vol_range": [0, 5 * 10 ** 8]
    });

    const [result, setResult] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sliderValue, setSliderValue] = useState([0, 3]);

    useEffect(() => {
        // console.log(selection)
    }, [selection])

    function fetchData() {
        setLoading(true);
        let encoded = {};
        Object.assign(encoded, selection);
        for (let i = 1; i <= 4; i++) {
            Object.keys(selection[i]).forEach(s => {
                if (!selection[i][s].selected)
                    delete selection[i][s];
            });
        }

        selection["vol_range"] = [getRealValue(sliderValue[0]), getRealValue(sliderValue[1])]

        fetch(`${url}/query_orats`, {
            method: "POST",
            body: JSON.stringify({"query": selection})
        }).then(r => r.json()).then(r => {
            setResult(r["symbols"]);
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        })
        console.log(JSON.stringify(selection))
    }

    const getRealValue = (value) => {
        if (value <= 1){
            return value * 300_000;
        }
        if (value <= 2){
            return 300_000 + 10*10**6 * (value-1);
        }
        if (value <= 3){
            return 10*10**6 + 200*10**6 * (value - 2);
        }
    }

    const valuetext = (value) => {
        value = getRealValue(value);
        value = parseFloat(value);
        if (value >= 200*10**6) return "200M+";
        if (value >= 10 ** 12) return (value / 10 ** 12).toFixed(1) + "T";
        if (value >= 10 ** 9) return (value / 10 ** 9).toFixed(1) + "B";
        if (value >= 10 ** 6) return (value / 10 ** 6).toFixed(1) + "M";
        if (value >= 10 ** 3) return (value / 10 ** 3).toFixed(1) + "K";
        return value.toFixed(1);
    }

    const marks = [
        {
            value: 0,
            label: "0"
        },
        {
            value: 1,
            label: "300K"
        },
        {
            value: 2,
            label: "10M"
        },
        {
            value: 3,
            label: "200M"
        }
    ];

    return (
        <div className="App">
            <Stack spacing={1} paddingLeft={10}>
                <Stack spacing={1} direction={"row"}>
                    <QueryForm title={"0-200M"} id={1} selection={selection} setSelection={setSelection}/>
                    <QueryForm title={"2B-10B"} id={2} selection={selection} setSelection={setSelection}/>
                    <QueryForm title={"10B+"} id={3} selection={selection} setSelection={setSelection}/>
                    <QueryForm title={"All"} id={4} selection={selection} setSelection={setSelection}/>
                </Stack>

                <Stack direction={"row"}>
                    <p>OR</p>
                    <Switch
                        value={selection["operator"] === "OR"}
                        onChange={e => setSelection({...selection, "operator": e.target.checked ? "AND" : "OR"})}
                    />
                    <p>AND</p>
                </Stack>
                <Box width={100}>
                    <TextField
                        id="standard-number"
                        label="Last X Days"
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={selection["days_range"]}
                        onChange={(e) => setSelection({...selection, "days_range": e.target.value})}
                        variant="standard"
                    />
                </Box>
                <Box width={100}>
                    <Select
                        labelId="demo-simple-select-label"
                        id="category-select"
                        value={selection["category"]}
                        label="Category"
                        size="small"
                        onChange={(e) => setSelection({...selection, "category": e.target.value})}
                    >
                        <MenuItem value={"all"}>All</MenuItem>
                        <MenuItem value={"etf"}>ETF</MenuItem>
                        <MenuItem value={"non_etf"}>Non ETF</MenuItem>
                    </Select>
                </Box>
                {(selection["category"] === "etf" || selection["category"] === "all") &&
                    <Box width={1000}>
                        <Slider
                            value={sliderValue}
                            onChange={(e) => setSliderValue(e.target.value)}
                            aria-labelledby="input-slider"
                            min={0}
                            max={marks.length-1}
                            step={0.00000001}
                            valueLabelDisplay="on"
                            getAriaValueText={valuetext}
                            valueLabelFormat={valuetext}
                            marks={marks}
                        />
                    </Box>
                }
                <Button style={{width: 100}} size={"small"} variant="contained"
                        onClick={() => fetchData()}>Submit</Button>
            </Stack>
            <Typography>Count: {result.length}</Typography>
            <Typography>
                {loading ? "Loading..." : result.join(", ")}
            </Typography>
        </div>
    );
}

export default App;
